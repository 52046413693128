import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { useThemeContext } from '../contexts/ThemeContext';

const BlogPage = () => {
  const { currentTheme } = useThemeContext();

  const posts = [
    {
      title: "Web Launch - 03/29/2023",
      content: "Syllacrostic, my first web game, officially launched on March 29, 2023. Promotion was mostly done through Reddit and various online communities, and it quickly gained traction. As more players discovered the game, its popularity grew, leading to a steady increase in daily plays. The web version became the foundation for future expansions, setting the stage for mobile launches and further growth.",
    },
    {
      title: "iOS Launch - 05/29/2023",
      content: "After the successful web launch, Syllacrostic was approved for distribution on iOS devices on May 29, 2023. This allowed iPhone and iPad users to download and play the game directly from the App Store. The mobile launch opened new doors for more players, increasing the game's reach significantly.",
    },
    {
      title: "Android Launch - 06/02/2023",
      content: "Following the iOS launch, Syllacrostic became available for Android devices. With the game now on both major mobile platforms, it reached a wider audience, leading to more engagement and feedback.",
    },
    {
      title: "CoolMathGames.com Partnership - 06/07/2023",
      content: "[CoolMathGames.com](https://www.coolmathgames.com/0-syllacrostic) reached out to host Syllacrostic on their platform. This partnership provided an incredible opportunity to expand the game's audience and introduce Syllacrostic to players who frequent Cool Math Games for brain teasers and logic puzzles.",
    },
    {
      title: "Syndication Across Major Sites - 10/01/2023",
      content: "By October 1, 2023, Syllacrostic was being distributed on over 100 sites, including prestigious platforms like [The Washington Post](https://games.washingtonpost.com/games/syllacrostic), [AARP](https://games.aarp.org/games/syllacrostic), and [USA TODAY](https://games.usatoday.com/games/syllacrostic). These major partnerships brought the game to new heights, offering players from around the world access to Syllacrostic on some of the most well-known news and gaming sites.",
    },
    {
      title: "5 Million Puzzles Solved - 03/18/2024",
      content: "On March 18, 2024, Syllacrostic passed a major milestone—5 million puzzles solved! This remarkable achievement reflected the game's growing popularity and the dedication of its player base.",
    },
    {
      title: "10 Million Puzzles Solved - 10/07/2024",
      content: "By October 7, 2024, Syllacrostic doubled its success by reaching an incredible 10 million puzzles solved. The game's community had grown exponentially, with more players engaging every day.",
    },
    {
      title: "Major UI Update - 10/24/2024",
      content: "On October 24, 2024, Syllacrostic received a major upgrade, including a revamped user interface with improved accessibility features. Players now have the option to toggle dark mode, adjust sounds, and even revisit past puzzles. This update was a direct result of player feedback, aiming to enhance the overall user experience and make the game even more enjoyable.",
    },
    {
      title: "Daily Double Added - 11/20/2024",
      content: "On November 20, 2024, Syllacrostic introduced an exciting new feature: the Daily Double puzzle. This daily puzzle offers double the syllables and double the clues, providing an extra layer of challenge for players seeking to test their skills.",
    },
  ];

  return (
    <Box
      sx={{
        padding: 4,
        backgroundColor: currentTheme.primaryColor,
        color: currentTheme.textColor,
        height: '100vh',
        maxHeight: '100vh',
        overflowY: 'auto',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {/* Centered content with max width */}
      <Box
        sx={{
          maxWidth: '550px',
          width: '100%',
          margin: '0 auto',
        }}
      >
        <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', mb: 2 }}>
          Syllacrostic Blog
        </Typography>

        <Divider sx={{ borderColor: currentTheme.textColor, margin: 1, width: '95%' }} />

        {/* Render each blog post */}
        {posts.map((post, index) => (
          <Box key={index} sx={{ mb: 4 }}>
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
              {post.title}
            </Typography>
            {/* Use ReactMarkdown to render markdown content */}
            <Box sx={{ mt: 1, mb: 2 }}>
              <ReactMarkdown>{post.content}</ReactMarkdown>
            </Box>
            {index < posts.length - 1 && <Divider sx={{ borderColor: currentTheme.textColor, my: 2 }} />}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default BlogPage;
